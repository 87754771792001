.main-header{
    background-size: cover;
    border-radius: rem-calc(4);
    overflow: visible;
    position: relative;
    &--small{
        @include breakpoint(small only){
            padding:rem-calc(50 40 40);
        }
        @include breakpoint(medium up) {
            > .grid-x {
                @include vertical-center;
                position: absolute !important;
                width: 100%;
            }
            @include aspect-ratio(16, 7);
        }
        @include breakpoint(large up) {
            @include aspect-ratio(16, 5);
        }
        @include breakpoint(xlarge up) {
            @include aspect-ratio(16, 4);
        }
    }
    &--medium{
        @include breakpoint(small only){
            padding:rem-calc(50 40 40);
        }
        @include breakpoint(medium up){
            @include aspect-ratio(16,8);
            >.grid-x{
                top:15%;
                position: absolute !important;
            }
        }
        @include breakpoint(large up){
            @include aspect-ratio(16,7);
            >.grid-x{
                top:15%;
            }
        }
        @include breakpoint(xlarge up){
            @include aspect-ratio(16,7);
            >.grid-x{
                top:20%;
            }
        }
    }
    &--large{
        @include breakpoint(small only){
            padding:rem-calc(50 40 40);
        }
        @include breakpoint(medium up){
            @include aspect-ratio(2,1);
            >.grid-x{
                @include vertical-center;
                position: absolute !important;
            }
        }
    }
    &--landing{
        padding:10vh 0;
        @include breakpoint(medium up){
            padding:10vh 0 15vh;
        }
    }
    &::after{
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background: linear-gradient(to right, rgba($secondary-color,1) 0%,rgba(0,0,0,0) 75%);
    }
    .grid-x{
        z-index: 2;
        position: relative;
    }
    &__container{
        @include page-grid-side-padding;
    }
    &__inner-container{
        position: relative;
        overflow: hidden;
        @include breakpoint(medium up){
            &::before{
                bottom:0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                z-index: 1;
                background-image: url("../images/svg/backgrounds/banner-overlay.svg");
                background-repeat: no-repeat;
                background-position: left bottom;
            }
        }
    }
    @include breakpoint(large up){
        .cell{
            &:first-child{
                padding-left: 15px;
            }
        }
    }
    h1,h2,h3,h4,h5,h6{
        color:$white;
        font-weight: 200;
        +p{
            margin-top: rem-calc(30);
        }
    }
    &__form-container{
        position: relative;
        @include breakpoint(small only){
            margin-top: rem-calc(20px);
        }
        @include breakpoint(medium up){
            >div{
                position: absolute;
                width: 100%;
            }
        }
    }

    &__modules {
        position: absolute;
    }

    &__blue-box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: absolute;

        width: 373px;
        top: 0;
        right: 0;
        height: 100%;
        padding-left: 40px;
        background-color: $blue;
        color: #fff;
        z-index: 2;
        li {
            color: #fff;
        }

        @include breakpoint(medium down){
            width: 200px;
            padding-left: 20px;
        }

        @include breakpoint(small down){
            display: none;
        }

        &-small {
            display: none;
            background-color: $blue;
            padding: 20px;
            margin: 0 20px;
            color: #fff;



            @include breakpoint(small only){
                display: block;
            }
        }
    }

    &__price {
        @extend h3;
        font-weight: bold !important;
    }

    &__signin {
        margin-top: 15px;
    }

    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
    }
}



