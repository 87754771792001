@import '../../dist/fonts/toi/style.scss';
$icon-toi : $icomoon-font-family;

.text-uppercase {
    text-transform: uppercase;
    background-color: transparent;
}
.text-bold {
    font-weight: bold;
}

