.modules{
    &__container{
        position: relative;
        background-color:$secondary-color;
        background-image: url("../images/svg/backgrounds/bg-grid--gray.svg");
        background-repeat: repeat;
    }
    &__image{
        background-size: cover;
        right:0;
        top:0;
        width: 100%;
        padding-bottom: 50%;
        height:100%;
        @include breakpoint(small only){
            margin-top: rem-calc(50px);
        }
        @include breakpoint(medium up){
            padding-bottom: 0;
            width:calc(50vw - 25px);
            position: absolute;
        }
    }
    &__tabs{
        margin-bottom: 20px;
        a{
            padding:0;
            background:transparent;
            font-size: rem-calc(16);
            color:$white;
            font-weight: 600;
            opacity: .75;
            text-transform: uppercase;
        }
    }
    &__item{
        &:hover,
        &.is-active{
            a{
                color:$white;
                font-weight: 700;
                opacity: 1;
                background:transparent;
            }
        }
        +li{
            margin-left: rem-calc(15px);
        }
    }
    &__content{
        background:transparent;
        border:none;
        h1,h2,h3,h4,h5,h6,p{
            color:$white;
        }
    }
}
