// @include responsive-font(5vw, 35px, 150px, 50px);
h1 {
    @include responsive-font(5vw, 30px, 40px, 40px);
    &.hero{
        @include responsive-font(5vw, 40px, 70px, 70px);
    }
}

h2{
    @include responsive-font(5vw, 30px, 40px, 40px);
}
h3{
    @include responsive-font(5vw, 25px, 30px, 30px);
}
h4{
    @include responsive-font(5vw, 18px, 24px, 24px);
}
h5{
    @include responsive-font(5vw, 16px, 18px, 18px);
}
h6{
    font-size: 16px;
}
