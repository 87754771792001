.module-detail {
    &__button {
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    &__cta {
        background: $blue;
        width: 100%;
        padding: 60px 40px;
        margin-top: 100px;
        margin-bottom: 100px;

        a {
            margin-top: 30px;
        }
    }
}

.detail-menu {
    padding-top: 50px;
    &__button {
        width: 90%;
        &.is-active {
            @extend .button--primary;
            width: 95%;
        }
        &:hover {
            @extend .button--primary;
            width: 95%;
        }
    }
}

