//Sticky footer
.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.page__content{
    @include page-padding;
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

.block__custom-columns{
    .cell{
        @include breakpoint(small only){

            &:not(.small-order-1){
                margin-top:rem-calc(50);
            }
            p{
                text-align: left !important;
            }
        }
    }
}
