.download-block{
    @include breakpoint(small only){
        padding:rem-calc(40);
        .cell{
            &:nth-child(n+2){
                margin-top:rem-calc(50);
            }
        }
    }
    @include breakpoint(medium only){
        padding:rem-calc(50 0);
        .cell{
            &:nth-child(n+2){
                margin-top:rem-calc(50);
            }
        }
    }
    background-color:$secondary-color;
    background-image: url("./../images/svg/backgrounds/download-block-bg--top.svg"), url("./../images/svg/backgrounds/download-block-bg--bottom.svg"), url("../images/svg/backgrounds/bg-grid--gray.svg");
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: top right, bottom right;
    border-radius: rem-calc(4px);
    padding-top:rem-calc(100);
    padding-bottom:rem-calc(100);
}
