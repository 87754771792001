.blog-detail{
    &__banner{
        @include aspect-ratio(16,9);
        @include breakpoint(medium up){
            @include aspect-ratio(16,5);
        }
        background-size: cover;
        position: relative;
        border-radius: rem-calc(20 20 0 0);
        .child-image{
            position: absolute;
            left: 0;
            object-fit: cover;
            @include vertical-center;
        }
    }
    &__content{
        padding:rem-calc(30px 25);
        @include breakpoint(medium up){
            padding:rem-calc(50px 0);
        }
        background:$white;
        border-radius: rem-calc(0 0 20 20);
    }
    &__details{
        color:$primary-color;
        font-size: rem-calc(12);
        font-family: $body-font-family;
        margin-bottom: rem-calc(15);
    }
}
