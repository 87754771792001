@each $colorName, $colorValue in $colors {
  .bg--#{$colorName} {
    background-color: $colorValue;
  }
}

$opacities: (
        '10' : 0.1,
        '20' : 0.2,
        '30' : 0.3,
        '40' : 0.4,
        '50' : 0.5,
        '60' : 0.6,
        '70' : 0.7,
        '80' : 0.8,
        '90' : 0.9,
);
@each $opacityName, $opacityValue in $opacities {
  .backgroundOpacity--#{$opacityName} {
    opacity: $opacityValue;
  }
}

.hasBackgroundImage {
  position: relative;
  .background {
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    & ~ * {
      position: relative;
    }
  }
  .backgroundCover {
    background-size: cover;
  }
  .backgroundRepeat {
    background-repeat: repeat;
  }
}

.hasParallax {
  overflow: hidden;
  .background {
    height: 180%;
    top: -40%;
  }
}


.bg-image{
    &--one{
        background-image: url("./../images/svg/backgrounds/bg-image-left--one.svg");
        background-repeat: no-repeat;
        background-position: top left;
    }
    &--two{
        background-image: url("./../images/svg/backgrounds/bg-image-left--two.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
    }
    &--three{
        background-image: url("./../images/svg/backgrounds/bg-image-left--three.svg");
        background-repeat: no-repeat;
        background-position: top left;
    }

    &--four{
        background-image: url("./../images/svg/backgrounds/bg-image-right--one.svg");
        background-repeat: no-repeat;
        background-position: top right;
    }

    &--five{
        background-image: url("./../images/svg/backgrounds/bg-image-right--two.svg");
        background-repeat: no-repeat;
        background-position: top right;
    }

    &--six{
        background-image: url("./../images/svg/backgrounds/bg-image-right--three.svg");
        background-repeat: no-repeat;
        background-position: top right;
    }


    //&--one{
    //    background-image: url("./../images/svg/backgrounds/download-block-bg--top.svg"), url("./../images/svg/backgrounds/download-block-bg--bottom.svg");
    //    background-repeat: no-repeat, no-repeat;
    //    background-position: top left, bottom right;
    //}
}
