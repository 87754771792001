.off-canvas{
    &__inner{
        padding-top:rem-calc(100px);
        ul{
            list-style-type: none;
            text-align: center;
            margin:0;
        }
        li{
            a{
                color:$secondary-color;
                font-size: rem-calc(18);
                font-weight: 600;
            }
            &.active{
                a{
                    color:$primary-color;
                }
            }
            +li{
                margin-top: 15px;
            }
        }
    }
}
