$icomoon-font-family: "toi" !default;
$icomoon-font-path: "fonts" !default;

$icon-arrow-slider-left: "\e912";
$icon-arrow-slider-right: "\e913";
$icon-hamburger: "\e911";
$icon-arrow-down--circle: "\e900";
$icon-arrow-left--circle: "\e901";
$icon-arrow-right--circle: "\e902";
$icon-arrow-up--circle: "\e903";
$icon-caret-down: "\e904";
$icon-caret-left: "\e905";
$icon-caret-right: "\e906";
$icon-caret-up: "\e907";
$icon-chevron-down--circle: "\e908";
$icon-chevron-down: "\e909";
$icon-chevron-left--circle: "\e90a";
$icon-chevron-left: "\e90b";
$icon-chevron-right--circle: "\e90c";
$icon-chevron-right: "\e90d";
$icon-chevron-up--circle: "\e90e";
$icon-chevron-up: "\e90f";
$icon-close: "\e910";

