[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	margin-bottom: 0;
    border-bottom:2px solid #DEDEDE;
    @include autofill;
    &:focus{
        border-bottom:2px solid #DEDEDE;
    }
}

.form-control.valid {
	border-color: $primary-color;
}
form.sending {
	.form-group {
		opacity: 0.5;
	}
}
.form__container {
	position: relative;
	z-index: 0;
	.form__loader {
		z-index: -1;
		opacity: 0;
		background-color: rgba($white, 0.7);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(all);
		svg {
			width: 50px;
			height: 50px;
			@include horizontal-center();
			top: 30%;
		}
	}
	&.sending {
		.form__loader {
			z-index: 1;
			opacity: 1;
		}
	}
}

.form-group{
    position: relative;
    +.form-group{
        margin-top: rem-calc(20px);
    }
    &:last-child{
        margin-top:rem-calc(50px);
    }

    label{
        @include transition(all);
        position: absolute;
        top: rem-calc(11);
        color:$body-font-color;
    }
    [type='text'],[type='email'],[type='tel'],textarea{
        z-index: 1;
        position: relative;
        height: rem-calc(50);
        &.is-invalid-input + label,
        &.valid + label,
        &:focus + label{
            top: rem-calc(-10);
            font-size: rem-calc(12);
            color:$primary-color;
        }
        &.is-invalid-input{
            background: transparent;
            border-color:$alert-color;
            + label{
                color:$alert-color;
            }
        }
        &.valid{
            color: $body-font-family;
            border-color: $success-color;
        }
        &[required]{
            + label{
                &::after{
                    content: '*';
                    color:$alert-color;
                }
            }
        }
    }
    textarea{
        height: rem-calc(150px);
        padding-top: 14px;
    }
    &.passed{
        [type='text'],[type='email'],[type='tel'],textarea{
            + label {
                top: rem-calc(-10);
                font-size: rem-calc(12);
                color: $success-color;
            }
        }
    }
    .form-error {
        margin-top: 7px;
        margin-bottom: 1.5rem;
    }
}
.form--light{
    .form-group,
    .mc-field-group{
        $body-font-color: $white;
        label{
            color:$body-font-color;
        }
        [type='text'],[type='email'],[type='tel'],textarea{
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus{
                -webkit-text-fill-color: $white !important;
            }
            color:$body-font-color !important;
            &.is-invalid-input + label,
            &.valid + label,
            &:focus + label{
                top: rem-calc(-10);
                font-size: rem-calc(12);
                color:$body-font-color !important;
            }
            &.valid{
                color: $body-font-family;
            }
        }
    }
}


#mc_embed_signup{
    width: 100%;
    p{
        margin-bottom: 0;
    }
    h2,.indicates-required{
        display: none;
    }
    .mc-field-group{
        margin-bottom: rem-calc(20px);
    }
    &_scroll{
        >div{
            &:last-child{
                @extend .button--primary;
                padding:0;
                &:hover{
                    padding:0;
                    input{
                        padding-right: rem-calc(25px);
                    }
                }
                input{
                    @include transition(all);
                    color:$white;
                    padding: rem-calc(14px 14px 14px 18px);
                    cursor: pointer;
                    background: transparent;
                    font-weight: 600;
                    height: 100%;
                    border:none;
                    text-transform: uppercase;
                }
            }
        }
    }
}

form{
    $background: $light-gray;
    $select-color: $body-font-color;
    $select-background: $white;
    $select-width: 100%;
    $select-height: rem-calc(50);
    $select-radius: rem-calc(0);

    select{
        font-size: rem-calc(16);
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding:rem-calc(0 35px 0 15px);
        height: $select-height;
    }

    .select-hidden {
        display: none;
        visibility: hidden;
        padding-right: 10px;
    }
    .select {
        display: inline-block;
        position: relative;
        color: $select-color;
        width: $select-width;
        height: $select-height;
        z-index: 5;
        font-size: rem-calc(16);
        font-weight: 400;
    }
    .select-styled {
        @include transition(all);
        border-radius: $select-radius;
        position: relative;
        background:transparent;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        left: 0;
        height: $select-height;
        padding:rem-calc(17px 22px 16px 0px);
        white-space: initial;
        border-bottom:2px solid #DEDEDE;
        &:after {
            @include transition(all);
            font-family:$icon-toi;
            content:$icon-caret-down;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            font-size: rem-calc(12);
            right: rem-calc(0);
            color:$body-font-color;
        }
        &:active, &.active {
            box-shadow: none;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    .select-options {
        @include transition(all);
        border-radius: 6px;
        display: none;
        background: $select-background;
        position: absolute;
        box-shadow: 0 3px 6px rgba(#000000, .16);
        border:2px solid $light-gray;
        top: 0;
        z-index: 2;
        right: 0;
        padding: rem-calc(15);
        left: 0;
        margin: 0;
        list-style: none;
        li{
            &:first-child{
                color: $secondary-color;
                background: $white;
                font-weight: 700;
                pointer-events: none;
            }
            +li{
                cursor: pointer;
                margin-top: 5px;
            }
            @include transition(all 0.15s ease-in);
            border-radius: rem-calc(6);
            margin: 0;
            padding:rem-calc(7 11px);
            white-space: normal;
            background: $white;
            &:hover {
                color: $secondary-color;
                background: $light-gray;
            }
            &[rel="hide"] {
                display: none;
            }
        }
    }
}
