.video-list{
    &__item{
        @include breakpoint(small only){
            &:nth-child(n+2){
                margin-top: 30px;
            }
        }
        @include breakpoint(medium up){
            &:nth-child(n+3){
                margin-top: 30px;
            }
        }
    }
}
