.experience-list{
    &__container{
        background-color:$secondary-color;
        background-image: url("./../images/svg/backgrounds/download-block-bg--top.svg"), url("./../images/svg/backgrounds/download-block-bg--bottom.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top right, bottom right;
        .swiper-slide{
            display: flex;
            height: 100%;
        }
    }
    &__item{
        flex-grow: 1;
        flex-direction: column;
        background:#0C0E1A;
        padding:rem-calc(50 50 45);
    }
    $image-height: 100px;
    &__logo{
        height: $image-height;
        margin-bottom: 30px;
        img{
            height: $image-height;
        }
    }
    h3{
        margin-bottom: rem-calc(20);
    }
    p{
        margin: 0;
        font-size: rem-calc(20);
    }
}
