.blog-list {
    &--overview{
        .list-complete-item {
            transition: all 1s;
            display: inline-block;
            margin-right: 10px;
        }

        .list-complete-enter, .list-complete-leave-to {
            opacity: 0;
            transform: translateY(30px);
        }

        .list-complete-leave-active {
            position: absolute;
        }
        .blog-list__item{
            &:first-child {
                @extend .grid-x;
                @extend .align-middle;
                flex:100%;
                background-color: #FAFAFA;
                .blog-list__image {
                    @extend .medium-6;
                    @extend .cell;
                    border-radius: rem-calc(6 0 0 6);
                    height: 100%;
                }
                .blog-list__content {
                    @extend .medium-6;
                    @extend .cell;
                    margin-left: 0;
                    padding:rem-calc(50)
                }
            }
            transition: all 150ms;
            overflow: hidden;
            //display: block;
            &:nth-child(n+2){
                margin-top:rem-calc(150);
            }
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
    }
    &__image {
        border-radius: rem-calc(6);
        background-size: cover;
        position: relative;
        overflow: hidden;
        @include aspect-ratio(16, 9);

        img {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            @include vertical-center;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        &-inner{
            flex-grow: 1;
        }
        padding: rem-calc(25 0 0);
        h6 {
            color: $body-font-color;
        }

        h3 {
            color: $secondary-color;
        }

        p {
            margin-top: 15px;
            color: $body-font-color;
            margin-bottom: 0;
            +p{
                margin-top: 20px;
            }
        }
    }
    @include breakpoint(small only) {
        .cell {
            &:nth-child(n+2) {
                margin-top: rem-calc(50);
            }
        }
    }
    @include breakpoint(medium only) {
        .cell {
            &:nth-child(n+3) {
                margin-top: rem-calc(50);
            }
        }
    }
}

.blog-filter{
    $background: $light-gray;
    $select-color: $body-font-color;
    $select-background: $white;
    $select-width: 100%;
    $select-height: rem-calc(60);
    $select-radius: rem-calc(6);

    select{
        cursor:pointer;
        font-size: rem-calc(16);
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding:rem-calc(0 35px 0 15px);
        height: $select-height;
    }

    .select-hidden {
        display: none;
        visibility: hidden;
        padding-right: 10px;
    }
    .select {
        cursor: pointer;
        display: inline-block;
        position: relative;
        color: $select-color;
        width: $select-width;
        height: $select-height;
        z-index: 1;
        font-size: rem-calc(16);
        font-weight: 600;
    }
    .select-styled {
        @include transition(all);
        border-radius: $select-radius;
        position: relative;
        background:$white;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        left: 0;
        height: $select-height;
        padding:rem-calc(17px 22px 16px 26px);
        white-space: initial;
        border:1px solid $light-gray;
        &:after {
            @include transition(all);
            font-family:$icon-toi;
            content:$icon-caret-down;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            font-size: rem-calc(12);
            right: rem-calc(20);
            color:$body-font-color;
        }
        &:hover {
        }
        &:active, &.active {

            box-shadow: none;
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .select-options {
        @include transition(all);
        border-radius: 6px;
        display: none;
        background: $select-background;
        position: absolute;
        box-shadow: 0 3px 6px rgba(#000000, .16);
        border:1px solid $light-gray;
        top: 0;
        z-index: 2;
        right: 0;
        padding: rem-calc(15);
        left: 0;
        margin: 0;
        list-style: none;
        li{
            &:first-child{
                &:hover{
                    background: $white;
                    color:$body-font-color;
                }
                padding:rem-calc(2px 7px 1px 11px);
                background: $white;
                color:$body-font-color;
            }
            +li{
                margin-top: 5px;
            }
            @include transition(all 0.15s ease-in);
            border-radius: rem-calc(6);
            margin: 0;
            padding:rem-calc(7 11px);
            white-space: normal;
            background: $white;
            &:hover {
                color: $secondary-color;
                background: $light-gray;
            }
            &[rel="hide"] {
                display: none;
            }
        }
    }
}
