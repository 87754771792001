.modules {
    margin-top: 150px;

    &__img {
        max-height: 327px;
        aspect-ratio: 4/3;
        width: 100%;
        object-fit: cover;
    }

    &__cell {
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
    }

    &__summary-text {
        margin-top: 15px;
        margin-bottom: 15px;
        flex: 2;
    }
}
