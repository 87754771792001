footer{
	background:#FAFAFA;
    border-top:1px solid $light-gray;
	position: relative;
	z-index: 1;
	padding:rem-calc(73 0);
	width: 100%;
	h4{
        font-size: rem-calc(16);
	}
	ul{
		margin:0;
		padding:0;
		list-style-type: none;
		li{
			margin:rem-calc(0 0 2px);
			padding:0;
		}
        a{
            color:$body-font-color;
            &:hover{
                color:$primary-color;
            }
        }
	}
	.footer{
		&__logo{
			max-width: rem-calc(100px);
		}
        &-logo{
            &__container{
                p{
                    font-size: rem-calc(20);
                    line-height: 1.3;
                    @include breakpoint(large up){
                        padding-right: rem-calc(50);
                    }
                }
            }
        }
	}
    @include breakpoint(small only) {
        .cell {
            text-align: center;
            &:nth-child(n+2) {
                margin-top: rem-calc(25);
            }
        }
    }
    @include breakpoint(medium only) {
        .cell {
            &:nth-child(n+4) {
                margin-top: rem-calc(25);
            }
        }
    }
}
