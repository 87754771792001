.review-carousel{
    &__container{
        overflow: hidden;
    }
    &__card{
        background:#FAFAFA;
        padding:rem-calc(15);
        border-radius: rem-calc(6);
        *{
            margin-bottom: 0;
        }
        p{
            font-size: rem-calc(20);
            color:$secondary-color;
        }
    }
    &__image{
        width: rem-calc(94px);
        padding-right: 15px;
        img{
            border-radius: rem-calc(6px);
            width: 100%;
        }
    }
}
