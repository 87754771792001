.faq{
    &-category{
        margin-bottom: rem-calc(14);
        &__item{
            @include breakpoint(medium up){
                +li{
                    margin-left:rem-calc(14) ;
                }
            }
            a{
                @include transition(all);
                border-radius: rem-calc(2);
                background:#FAFAFA;
                font-weight: 300;
                color:$body-font-color;
                @include breakpoint(small only){
                    padding:rem-calc(25px)
                }
            }
            &.is-active,
            &:hover{
                >a{
                    background:$secondary-color;
                    color:$white;
                }
            }
        }
    }
    &-question{
        &__item {
            margin-top: rem-calc(14);
            border:1px solid transparent;
            .accordion-title{
                border-radius: rem-calc(2);
                background:#FAFAFA;
                font-size: rem-calc(20);
                color:#13112F;
                &::before{
                    font-size: rem-calc(10);
                    font-family: $icon-toi;
                    content: $icon-caret-down;
                    color:$body-font-color;
                }
            }
            .accordion-content{
                padding: rem-calc(0 25px 5);
                @include breakpoint(medium up){
                    padding: rem-calc(0 50px 25);
                }
            }
            &.is-active{
                border:1px solid #EAEAEA;
                .accordion-title{
                    padding-bottom: 20px;
                    background:$white;
                    font-weight: 700;
                    &::before{
                        top: 65%;
                        content: $icon-caret-up;
                        color:$secondary-color;
                    }
                }
            }
        }
    }
}
