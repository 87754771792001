@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?q2cg6f');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?q2cg6f#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?q2cg6f') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?q2cg6f') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?q2cg6f##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-slider-left {
  &:before {
    content: $icon-arrow-slider-left; 
  }
}
.icon-arrow-slider-right {
  &:before {
    content: $icon-arrow-slider-right; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-arrow-down--circle {
  &:before {
    content: $icon-arrow-down--circle; 
  }
}
.icon-arrow-left--circle {
  &:before {
    content: $icon-arrow-left--circle; 
  }
}
.icon-arrow-right--circle {
  &:before {
    content: $icon-arrow-right--circle; 
  }
}
.icon-arrow-up--circle {
  &:before {
    content: $icon-arrow-up--circle; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-chevron-down--circle {
  &:before {
    content: $icon-chevron-down--circle; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left--circle {
  &:before {
    content: $icon-chevron-left--circle; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right--circle {
  &:before {
    content: $icon-chevron-right--circle; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up--circle {
  &:before {
    content: $icon-chevron-up--circle; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}

