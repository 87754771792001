header{
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	position: relative;
	.child-image{
		position: absolute;
		left: 0;
		width: 100%;
		@include vertical-center;
	}
}
