$primary-color: #E4001B;
$secondary-color: #15192F;
$tertiary-color: #00B1FF;
$body-font-color: #9A9A9A;
$white: #ffffff;
$light-gray: #EAEAEA;
$soft-gray: #FAFAFA;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #cc4b37;
$blue: #002A43;

$colors: (
		'primary' : $primary-color,
		'secondary' : $secondary-color,
		'tertiary' : $tertiary-color,
		'body' : $body-font-color,
		'white' : $white,
		'gray' : $light-gray,
		'soft-gray' : $soft-gray,
		'success' : $success-color,
		'alert' : $alert-color,
        'blue' : $blue
);
