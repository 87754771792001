@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;
    @media (max-#{$dimension}: #{$min-breakpoint}) {
        font-size: $min;
    }
    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;
        @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
        }
    }
    @if $fallback {
        font-size: $fallback;
    }
    font-size: $responsive;
}

@mixin responsive-height($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;
    @media (max-#{$dimension}: #{$min-breakpoint}) {
        height: $min;
    }
    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;
        @media (min-#{$dimension}: #{$max-breakpoint}) {
            height: $max;
        }
    }
    @if $fallback {
        height: $fallback;
    }
    height: $responsive;
}

@mixin responsive-padding($padPosition,$responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;
    @media (max-#{$dimension}: #{$min-breakpoint}) {
        padding-#{$padPosition}: $min;
    }
    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;
        @media (min-#{$dimension}: #{$max-breakpoint}) {
            padding-#{$padPosition}: $max;
        }
    }
    @if $fallback {
        padding-#{$padPosition}: $fallback;
    }
    padding-#{$padPosition}: $responsive;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin autofill {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        color: $white !important;
        //border: $input-border !important;
        -webkit-text-fill-color: $secondary-color !important;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
    }
}

@mixin box-shadow($value){
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin banner-shadow{
    -webkit-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
    box-shadow: 0 15px 30px rgba(0,0,0,0.16);
}

@mixin drop-shadow{
    -webkit-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
    box-shadow: 0 15px 30px rgba(0,0,0,0.16);
}

@mixin translateY($prop) {
    -webkit-transform: translateY($prop);
    -moz-transform: translateY($prop);
    -ms-transform: translateY($prop);
    -o-transform: translateY($prop);
    transform: translateY($prop);
}

@mixin translateX($prop) {
    -webkit-transform: translateX($prop);
    -moz-transform: translateX($prop);
    -ms-transform: translateX($prop);
    -o-transform: translateX($prop);
    transform: translateX($prop);
}

@mixin translateXY($prop) {
    -webkit-transform: translateX($prop) translateY($prop);
    -moz-transform: translateX($prop) translateY($prop);
    -ms-transform: translateX($prop) translateY($prop);
    -o-transform: translateX($prop) translateY($prop);
    transform: translateX($prop) translateY($prop);
}

@mixin transform($prop) {
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -ms-transform: $prop;
    -o-transform: $prop;
    transform: $prop;
}

@mixin transition($prop, $duration: 150ms) {
    -webkit-transition: $prop $duration ease-in-out;
    -moz-transition: $prop $duration ease-in-out;
    -ms-transition: $prop $duration ease-in-out;
    -o-transition: $prop $duration ease-in-out;
    transition: $prop $duration ease-in-out;
}

@mixin cover-bg{
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

@mixin text-spects {
    h1,h2,h3,h4,h5,h6,p{
        @content
    }
}

@mixin landscape-medium-down{
    @include breakpoint(medium down) {
        @include breakpoint(landscape){
            @content
        }
    }
}


@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@mixin fadeIn{
    opacity:0;
    animation:fadeIn ease-in 1;
    animation-fill-mode:forwards;
    animation-duration:500ms;
    animation-delay: 500ms;
}

@mixin linear-gradient--vertical($from, $to) {
    background: linear-gradient(to bottom, $from 0%,$to 100%);
}
@mixin linear-gradient--horizontal($from, $to) {
    background: linear-gradient(to right, $from 0%,$to 100%);
}

@mixin mobile-side-padding{
    @include breakpoint(small only){
        padding-left:5px;
        padding-right: 5px;
    }
    @include breakpoint(medium up){
        padding-left:25px;
        padding-right: 25px;
    }
}

@mixin page-padding{
    padding-top: rem-calc(70);
    @include breakpoint(large up){
        padding-top: rem-calc(110);
    }
}

@mixin basic-drop-shadow{
    box-shadow: 15px 15px 30px rgba(#000000, .2);
}

@mixin page-grid-side-padding{
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
    @include breakpoint(medium up){
        padding-left: rem-calc(50px);
        padding-right: rem-calc(50px);
    }
    @include breakpoint(large up){
        padding-left: rem-calc(100px);
        padding-right: rem-calc(100px);
    }
}
