.breadcrumbs{
    margin:rem-calc(0 0 40);
    list-style-type: none;
    li{
        display: inline-block;
        &::after{
            content: '/';
            margin: 0 5px;
        }
        opacity: .5;
        &.active,
        &:last-child{
            opacity: 1;
        }
        &:hover{
            opacity: 1;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
    }
    a,span{
        color:$black;
        font-size: rem-calc(14);
    }
    &--header{
        a,span{
            color:$white;
            font-size: rem-calc(14);
        }
    }
}
