.video-header{
    border-radius: rem-calc(4);
    padding:10vh 0;
    @include breakpoint(small only){
        .cell{
            &:nth-child(n+2){
                margin-top:rem-calc(30)
            }
        }
    }
    @include breakpoint(medium up){
        padding:15vh 0;
    }
    &__container{
        @include page-grid-side-padding;
    }
    h1,h2,h3,h4,h5,h6{
        color:$white;
        font-weight: 200;
        +p{
            margin-top: rem-calc(30);
        }
    }
}
