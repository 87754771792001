.main-menu{
    @include menu-base;
    &__container{
        z-index: 50;
        width:100%;
        position: fixed;
        top: 0;
        left: 0;
        background:$white;
        @include page-grid-side-padding;
        border-bottom:rem-calc(1) solid $light-gray;
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
        @include breakpoint(large up){
            padding-top: rem-calc(34);
            padding-bottom: rem-calc(34);
        }
        .logo{
            display: inline-block;
            width:rem-calc(164);
            @include breakpoint(small only){
                &__container{
                    text-align: center;
                }
            }
        }
    }
    @include breakpoint(small only) {
        &__controls {
            position: absolute;
            right:0;
            top: rem-calc(20);
        }
    }
    &__item{
        margin-left: rem-calc(15);
        &.login{
            a{
                color:$primary-color;
                font-weight: 700;
            }
        }
        a{
            @include transition(all);
            padding:rem-calc(0 0 0 10);
            color:$body-font-color;
            font-size: rem-calc(16);
            position: relative;
            display: inline-block;
            &::after{
                @include vertical-center;
                opacity: 0;
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                left: rem-calc(0);
                border-style: solid;
                border-width: 3px 0 3px 6px;
                border-color: transparent transparent transparent $primary-color;
            }
        }
        &:hover,
        &.is-active{
            a{
                &::after{
                    opacity: 1;
                }
                font-weight: 600;
                color:$secondary-color;
            }
        }
    }
}

.menu-button {
	width: 40px;
	height: 40px;
	border-radius: 0;
	padding: 0;
	position: relative;
	right: 0;
	top: 3px;
	&::before{
        font-family: $icon-toi;
        content: $icon-hamburger;
        font-size: rem-calc(25);
    }
}
.is-off-canvas-open{
    .menu-button {
        &::before{
            content: $icon-close;
            font-size: rem-calc(20);
        }
    }
}
