*{
	outline: none;
}

a{
	@include transition(color);
	&:hover, &:focus {
	}
    &.header-link{
        font-size: rem-calc(16);
        font-weight: 500;
        margin-left: 10px;
    }
}
