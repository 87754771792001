@each $colorName, $colorValue in $colors{
	.button--#{$colorName}{
		@include transition(all);
        font-weight:600;
        padding: rem-calc(14px 14px 14px 18px);
        margin-right: rem-calc(15);
		display: inline-block;
        height: rem-calc(53px);
        background:$colorValue;
        white-space: nowrap;
		@if $colorName == 'white' {
			color: $primary-color;
		}@else if $colorName == 'gray' {
            color: $black;
        }@else {
			color:$white;
		}
        position: relative;
        z-index: 1;
        &::after{
            @include transition(all);
            top:0;
            position: absolute;
            content: '';
            display: block;
            z-index: -1;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: rem-calc(26.5px 0 26.5px 15px);
            border-color: transparent transparent transparent $colorValue;
            transform: translateX(100%);
            right: 0;
        }
		&:hover,&:focus{
            @if $colorName == 'white' {
                color: $primary-color;
            }@else if $colorName == 'gray' {
                color: $white;
                background:$black;
                &::after{
                    border-color: transparent transparent transparent $black;
                }
            }@else {
                color:$white;
            }
            padding-right: rem-calc(25px);
		}
	}
}

.arrow-link{
    &::before{
        font-family: $icon-toi;
        content: $icon-caret-right;
        margin-right: rem-calc(5);
        font-size: rem-calc(11);
        position: relative;
        top: -1px;
    }
}
