//Use when necessary
ul{
    &.social-list{
        list-style-type: none;
        margin:0;
        li{
            display: inline-block;
            +li{
                margin-left: rem-calc(13);
            }
            i{
                @include transition(all);
                color:$secondary-color;
            }
            &:hover{
                i{
                    color:$primary-color;
                }
            }
        }
        &--horizontal{
            li{
                +li{
                    margin-left: rem-calc(25);
                }
                i{
                    color:$white;
                    margin-right: 5px;
                }
                a{
                    color:rgba($white,.75);
                    &:hover{
                        color:$primary-color
                    }
                }
            }
        }
    }
}
