@include breakpoint(medium up) {
    .has-border{
        &--middle{
            position: relative;
            &::before{
                left: 50%;
                height: 100%;
                width: 1px;
                position: absolute;
                background: $light-gray;
                content:'';
                top: 0;
            }
        }
    }
}
