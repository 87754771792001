.usp-list{
    &__item{
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        @include breakpoint(small only){
            &:nth-child(n+2){
                margin-top: 50px;
            }
            padding-right: 0;
            text-align: center;
            .text-left{
                text-align: center;
            }
        }
        //@include breakpoint(large up) {
        //    padding-right: 8.3%;
        //}
        a{
            font-weight: 600;
        }
        h3 {
            word-break: break-word;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        &-inner{
            flex-grow: 1;
        }
    }
}
@include breakpoint(large up){
    .usp-list{
        &.large-up-2{
            .usp-list__item{
                &:nth-child(n+3){
                    margin-top: 50px;
                }
            }
        }
        &.large-up-3{
            .usp-list__item{
                &:nth-child(n+4){
                    margin-top: 50px;
                }
            }
        }
        &.large-up-4{
            .usp-list__item{
                &:nth-child(n+5){
                    margin-top: 50px;
                }
            }
        }
    }
}
