.newsletter-block{
    &__container{
        border-top:1px solid $light-gray;
        background:#FAFAFA;
        padding:4vh 0;
    }
    form{
        #mc_embed_signup_scroll{
            @extend .grid-x;
            @extend .grid-margin-x;
            @include breakpoint(small only){
             padding-top: 20px;
                margin:0 !important;
            }
        }
        .form-group,
        .mc-field-group{
            @extend .cell;
            @extend .auto;
            margin-top: 0;
            margin-bottom: 0 !important;
            button{
                display: block;
                margin:0 auto;
            }
            @include breakpoint(small only){
                padding:0;
                width: 100%;
                min-width: 100%;
                display: block;
                margin:0 !important;
                margin-bottom: rem-calc(25px) !important;
                text-align: left;
                button{
                    margin:0;
                }
            }
        }
    }
    #mc_embed_signup{
        width: 100%;
        p{
            margin-bottom: 0;
        }
        h2,.indicates-required{
            display: none;
        }
        &_scroll{
            div{
                &:last-child{
                    @extend .button--gray;
                    padding:0;
                    &:hover{
                        padding:0;
                        input{
                            color:$white;
                            padding-right: rem-calc(25px);
                        }
                    }
                    input{
                        @include transition(all);
                        padding: rem-calc(14px 14px 14px 18px);
                        cursor: pointer;
                        background: transparent;
                        color:$body-font-color;
                        font-weight: 600;
                        height: 100%;
                        border:none;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
