.employee-list{
    &__image{
        background-repeat: no-repeat;
        overflow: hidden;
    }
    &__item{
        width: 100%;
    }
    &__content{
        @include breakpoint(medium up){
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
        }
        padding:rem-calc(25 15);
        h6{
            font-weight: 700;
            color:$secondary-color;
        }
        p{
            margin:0;
        }
    }
    &__container{
        text-align: center;
        position: relative;
        .swiper-button-prev{
            justify-content: flex-start;
            left: 0;
            background: -moz-linear-gradient(left, rgba($white, 1) 0, rgba($white, 0) 100%);
            background: -webkit-linear-gradient(left, rgba($white, 1) 0, rgba($white, 0) 100%);
            background: linear-gradient(to right, rgba($white, 1) 0, rgba($white, 0) 100%);
            @include breakpoint(medium up) {
                background: -moz-linear-gradient(left, rgba($white, 1) 50px, rgba($white, 0) 100%);
                background: -webkit-linear-gradient(left, rgba($white, 1) 50px, rgba($white, 0) 100%);
                background: linear-gradient(to right, rgba($white, 1) 50px, rgba($white, 0) 100%);
            }
            &::after{
                content: $icon-arrow-slider-left;
                left: rem-calc(-10);
            }
        }
        .swiper-button-next{
            justify-content: flex-end;
            right: 0;
            text-align: right;
            background: -moz-linear-gradient(right,  rgba($white,1)      0,rgba($white,0)  100%);
            background: -webkit-linear-gradient(right,  rgba($white,1)   0,rgba($white,0)  100%);
            background: linear-gradient(to left,  rgba($white,1)         0,rgba($white,0)  100%);
            @include breakpoint(medium up){
                background: -moz-linear-gradient(right,  rgba($white,1)      50px,rgba($white,0)  100%);
                background: -webkit-linear-gradient(right,  rgba($white,1)   50px,rgba($white,0)  100%);
                background: linear-gradient(to left,  rgba($white,1)         50px,rgba($white,0)  100%);
            }
            &::after{
                content: $icon-arrow-slider-right;
                right: rem-calc(-10);
            }
        }
    }
    &__nav{
        margin:0;
        height: calc(100% - 100px);
        top:0;
        width: rem-calc(100);
        @include breakpoint(medium up) {
            height: calc(100% - 102px);
            width: rem-calc(250);
        }
        &::after{
            color:$secondary-color;
            font-size: rem-calc(80);
            line-height: 1;
            position: relative;
            font-family: $icon-toi;
        }
    }

}

.block__employee-list{
    .grid-container{
        padding:0;
    }
}


.employee-list__container.no-carousel {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .employee-list__item {
        width: auto;
        margin: 0 20px;
        img {
            height: 400px;
            width: 300px;
            object-fit: cover;
            @include breakpoint(small down){
                height: 300px;
                width: 250px;
            }
        }
    }
}
